import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/directives'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import {
	openSetPasswd
} from './components/setLoginPassword';
import 'font-awesome/css/font-awesome.css'

Vue.config.productionTip = false

Vue.config.ignoredElements = ['wx-open-launch-weapp']
// 添加全局打开修改密码
Vue.prototype.openSetPasswd = openSetPasswd;

Vue.prototype.serverUrl=process.env.VUE_APP_PROXY_URL;

Vue.use(Element, {
	size: Cookies.get('size') || 'medium', // set element-ui default size

})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')

// const ratio = window.devicePixelRatio;
// if(ratio != 1){
//   document.body.style.zoom = -0.6*ratio + 1.6
// }